import "./Home.css";

import DuckFeed from '../../DuckFeed/DuckFeed';

const Home = () => {
  return (
    <div className="Home">
      <DuckFeed />
    </div>
  );
};

export default Home;
